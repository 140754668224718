import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Group,
  Done,
} from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";
import vectorizeText from "vectorize-text";


const Izci = () => {
  const { apiKey, user_id, userMail } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [lastData, setLastData] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);

  var demolast;
  const navigate = useNavigate();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    function Fetching() {
      fetch(
        "https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("first fetch", result);
          console.log(result.body.filter((obj) => !obj.deleted_at));
          var resultfilter = result.body.filter(
            (obj) => !obj.deleted_at && obj.type == "izci"
          );
          //  resultfilter.map((e)=>{delete e.led_status ; delete e.last_measure;delete e.motor_status  ; delete e.green_led_status; delete e.red_led_status})
          console.log("resultfilter", resultfilter);
          var resultsort = resultfilter.sort(function (a, b) {
            return b.id - a.id;
          });

          var tempTable;
          var asd = [];
          
          setTableData([...resultsort]);
          console.log("resultsotr", resultsort);
        })
        .then(() => setLoading(false))
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
    Fetching();
  }, [postdata]);

  const handleCreateNewRow =  (values,piece) => {
    console.log("values: ", values);
    values.type = "izci";
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    values.piece = piece
    values.lastid = tableData?.[0]?.id ?tableData[0].id : 0
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values),
    };

    fetch(
      "https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .then(setpostdata(!postdata))
      
      .catch((error) => console.log("error", error));

  };
  // const handleCreateNewRow = async (values,piece) => {
  //   console.log("values: ", values);
  //   console.log("piece: ", piece);
  //   values.type = "izci";
  
  //   const fetchRequests = []; // Fetch isteklerini depolamak için dizi oluştur
  
  //   for (let i = 0; i < piece; i++) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("apikey", apiKey);
  //     myHeaders.append("userid", user_id);

  //     let number = (Number(tableData?.[0]?.id ? tableData[0].id : 0) + i +1)
  //     let formattedNumber = number.toString().padStart(8, '0');
     

  //     values.id = formattedNumber
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: JSON.stringify(values),
  //     };
  
  //     fetchRequests.push(
  //       fetch(
  //         "https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .catch((error) => console.log("error", error))
  //     );
  //   }
  
  //   try {
  //     const results = await Promise.all(fetchRequests); // Tüm fetch isteklerinin tamamlanmasını bekler
  //     console.log("Tüm sonuçlar:", results);
  //     setpostdata(!postdata); // Burada setpostdata ile ilgili işlemi yapabilirsin, eğer gerekirse
  //   } catch (error) {
  //     console.error("Bir hata oluştu:", error);
  //   }
  // };  

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var deletebody = { deleted_at: "12" };
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(deletebody),
      };

      fetch(
        `https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
 const colObj = userMail.includes("@yt.com.tr") ? [


{
  accessorKey: "qr",
  header: "qr",
  enableColumnOrdering: false,
  enableEditing: false, //disable editing on this column
  enableSorting: false,
  size: 80,
  Cell: ({ cell }) => (
    <>
      {/* <QRCode
        id={cell.row.original.id}
        size={20}
        style={{
          height: "auto",
          width: "70px",
        }}
        value={cell.row.original.id}
        viewBox={`0 0 20 20`}
      /> */}
      <QRCodeSVG id={cell.row.original.id}
        size={20}
        style={{
          height: "auto",
          width: "70px",
        }}
        bgColor="#ffffff00"
        value={cell.row.original.id}
        level="H"
        minVersion={2}
 />
        
    </>
  ),
}] : []
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "group_name",
      //   header: "group_name",
      //   enableEditing: false,
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
        
      },
      {
        accessorKey: "model",
        header: "model",
        size: 80,
      },
      {
        accessorKey: "malzeme",
        header: "malzeme",
        size: 80,
      },
      {
        accessorKey: "üretim_tarihi",
        header: "üretim_tarihi",
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "kurulum_tarihi",
        header: "kurulum_tarihi",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "konum",
        header: "konum",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            
            <div>
            <span>{cell.row.original.konum?.lat}{cell.row.original.konum?.lat? ",":""}</span>
            <span>{cell.row.original.konum?.lon}</span>
            </div>
          </>
        ),
      },
      {
        accessorKey: "operator_notu",
        header: "operator_notu",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "kuran",
        header: "kuran",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "kaldırma_tarihi",
        header: "kaldırma_tarihi",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "kaldıran",
        header: "kaldıran",
        enableEditing: false,
        size: 80,
      },
      ...colObj
    ],
    [getCommonEditTextFieldProps]
  );



  const downloadQRCode = (row) => {
    console.log("row : ", row.original);

    // QR kodu SVG'yi al
    var svg = document.getElementById(row.original.id);

    // Container SVG oluştur
    let containerSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    containerSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    // QR kodu için bir grup öğesi oluştur
    let qrGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
    qrGroup.setAttribute("transform", "translate(0, 0)"); // QR kodunu sağa 20 birim kaydır

    // QR kodu SVG'sini gruba ekle
    qrGroup.appendChild(svg.cloneNode(true));

    // QR grup öğesini container SVG'ye ekle
    containerSvg.appendChild(qrGroup);

    // Y offset değeri
    const yOffset = 27;
    const xOffset = 2;

    // Metni path'e dönüştür
    const polygons = vectorizeText(row.original.id, {
        font: "Arial",
        fontStyle: "normal",
        polygons: true,
        width: 20, // Yazının genişliğini ayarlayın
        textBaseline: "hanging", // Text'in konumlandırılması
    });

    // Path öğeleri oluştur ve ekle
    polygons.forEach((loops) => {
        loops.forEach((loop) => {
            let pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");

            // Path verisini oluştur
            let pathData = 'M ' + (loop[0][0] + xOffset )+ ' ' + (loop[0][1] + yOffset); // yOffset ekliyoruz
            for (let i = 1; i < loop.length; i++) {
                pathData += ' L ' + (loop[i][0] + xOffset) + ' ' + (loop[i][1] + yOffset); // yOffset ekliyoruz
            }
            pathData += ' L ' + (loop[0][0] + xOffset )+ ' ' + (loop[0][1] + yOffset); // Yolu kapatırken yOffset ekliyoruz

            // Path elementini yapılandır
            pathElement.setAttribute("d", pathData);
            pathElement.setAttribute("fill-rule", "evenodd"); // İç içe alanları doğru doldurur
            pathElement.setAttribute("fill", "none"); // Harf rengi
            pathElement.setAttribute("stroke", "black"); // Çizgi rengi
            pathElement.setAttribute("stroke-width", "0.2"); // Çizgi kalınlığı

            // Path öğesini container SVG'ye ekle
            containerSvg.appendChild(pathElement);
        });
    });

    // Container SVG'yi XML string'ine dönüştür
    var svgSource = new XMLSerializer().serializeToString(containerSvg);

    // SVG kaynağını veri URL'sine dönüştür
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgSource);

    // İndirme linki oluştur
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;

    // İndirme linkini body'ye ekle
    document.body.appendChild(downloadLink);

    // İndirme işlemini başlat
    downloadLink.click();

    // Temizlik yap: İndirme linkini body'den kaldır
    document.body.removeChild(downloadLink);
};



  
  

  function handleOpenInNew(row) {
    console.log(row.original.id);
    // navigate(row.original.id, '_blank');
    window.open(`/izci/${row.original.id}`, "_blank");
  }
  function handleOpenCalibration(row) {
    navigate(`${row.original.id}/calibration`);
  }
  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function fetchLast(data) {
    fetch(
      `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${data.id}&type=last&izcilength=${data.izci_length}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const resultfilter = JSON.parse(result.body);
        Object.keys(resultfilter).map((e, i) => {
          data[e] = resultfilter[e];
        });
      })
      .catch((err) => {
        console.log("lasdatafetcherr : ", err);
      });
    const original = [
      "coords",
      "izci_hw_group",
      "izci_length",
      "qr",
      "created_at",
      "izci_packet_ver",
      "izci_hw_cfg",
      "izci_hw_rev",
      "izci_hw_ver",
      "owner_id",
      "produced_at",
      "izci_sw_ver",
      "id",
      "type",
      "time",
    ];
    return (
      <div className="flex flex-row  ">
        <p className="text-xl font-bold text-blue-600 mr-5 items-center">
          <p>last measure </p> <p>{formatDate(data.time)}</p>
        </p>
        {Object.keys(data).map((e, i) => {
          if (!original.includes(e)) {
            return (
              <div
                key={Math.random()}
                className="flex flex-col justify-center items-center bg-slate-200 "
              >
                <p className="text-lg font-bold text-gray-600 px-5">{e}</p>
                <p className="text-xl font-bold bg-white w-full px-5">
                  {Number(data[e]).toFixed(1)}
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  }

  const handleSetGroup = async (data, groupName) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ group_name: groupName }),
    };

    data.map((e) => {
      fetch(
        `https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci/${e.original.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    });
    setIsGroupModal(false);

    // fetch(
    //   `https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => console.log(result))
    //   .then(setpostdata(!postdata))
    //   .catch((error) => console.log("error", error));
  };
  function getAll() {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      "https://vpsa41jgcd.execute-api.eu-west-1.amazonaws.com/v1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("noldubize: ", result);
        console.log(result.body.filter((obj) => !obj.deleted_at));
        const resultfilter = result.body.filter(
          (obj) => !obj.deleted_at && obj.type == "izci"
        );

        const resultsort = resultfilter.sort(function (a, b) {
          return b.produced_at - a.produced_at;
        });

        console.log("resultsort : ", resultsort);
        setTableData(resultsort);
      })
      .then(() => setLoading(false))

      .catch((error) => console.log("error", error));
  }

  return (
    <>
      {!loading ? (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="modal" //default
          enableColumnOrdering
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          // renderDetailPanel={({ row }) => fetchLast(row.original)}
          enableRowNumbers={userMail.includes('@yt.com.tr') ? true :false}
          enableRowActions={userMail.includes('@yt.com.tr') ? true :false}
          enableRowSelection={userMail.includes('@yt.com.tr') ? true :false}
          enableTopToolbar={userMail.includes('@yt.com.tr') ? true :false}
          enableGrouping
          enableStickyHeader
          positionToolbarAlertBanner="bottom"
          initialState={{
            density: "compact",
            expanded: true,
            // grouping: ["group_name"],
            pagination: { pageSize: 100, pageIndex: 0 },
            sorting: [
              {
                id: 'id', //sort by age by default on page load
                desc: true,
              },
            ],
          }}
          state={{
            isLoading: loading,
          }}
          renderRowActionMenuItems={({ closeMenu, table, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                table.setEditingRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                handleDeleteRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              Delete
            </MenuItem>,

            <MenuItem
              key={2}
              onClick={() => {
                downloadQRCode(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              Download QR Code
            </MenuItem>,
            // <MenuItem
            //   key={3}
            //   onClick={() => {
            //     handleOpenCalibration(row);
            //     closeMenu();
            //   }}
            //   sx={{ m: 0 }}
            // >
            //   <ListItemIcon>
            //     <OpenInNew />
            //   </ListItemIcon>
            //   Calibration
            // </MenuItem>,
          ]}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                Create New Device
              </Button>
              {/* <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => {
                  setIsGroupModal(true);
                  setSelectedRows(table.getSelectedRowModel().rows);
                }}
                variant="contained"
              >
                Set Group
              </Button> */}
            </Box>
          )}
        />
      ) : (
        <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
          <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
        </div>
      )}

      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <Modal
        open={isGroupModal}
        onClose={() => setIsGroupModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 bg-white rounded-md  shadow-24 p-4  flex flex-col">
          <p className="text-2xl w-full  text-center pt- pb-4">Group Name</p>
          <div className="flex flex-col gap-2">
            <TextField
              id="outlined-basic"
              label="Group Name"
              variant="standard"
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
            />
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => setIsGroupModal(false)}
              variant="text"
            >
              Cancel
            </Button>
            <Button
              //  disabled={ }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                handleSetGroup(selectedRows, groupName);
                getAll();
              }}
              startIcon={<Done />}
              variant="contained"
            >
              Apply
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [piece, setPiece] = useState(1);
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey] = "";
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values,piece);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle  textAlign="center">Create New Device</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              padding:"0.5rem"
            }}
          >
            <TextField  
                  
                  label={"Adet"}
                  name={"Piece"}
                  value={piece}
                  onChange={(e) =>
                    setPiece(prev => e.target.value)
                  } />
            {columns.map((column) =>
              column.enableEditing == false || column.header == "length" ? (
                ""
              ) : (
                
                
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      izci_length: 60,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                
              )
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Create New Device
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;

export default Izci;
